import React from 'react'
import { Link } from 'gatsby'
import { skipNoReferrer } from '../utils/url_helpers'

const ExternalLink = ({
  children,
  href,
}: {
  children: JSX.Element
  href: string
}) => {
  if (href[0] === '/') {
    return <Link to={href}>{children}</Link>
  }
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      href={href}
      target="_blank"
      rel={`noopener ${skipNoReferrer(href) ? '' : 'noreferrer'}`}
    >
      {children}
    </a>
  )
}

export default ExternalLink

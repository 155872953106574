import * as React from 'react'
import Layout from 'components/Layout'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import ExternalLink from 'components/ExternalLink'
import Seo from './Seo'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TermsPage = ({ pageContext }: any) => {
  return (
    <Layout>
      <div className="pb-15 inner-container privacy-policy">
        <MDXProvider
          components={{
            a: ExternalLink,
          }}
        >
          <MDXRenderer>{pageContext.body}</MDXRenderer>
        </MDXProvider>
      </div>
    </Layout>
  )
}

export default TermsPage

export const Head = () => {
  return (
    <Seo
      title="Terms of Service"
      description="Xanadu Quantum Technologies Inc. terms & conditions"
    />
  )
}
